@import '../../../assets/styles/mixins';
@import 'sass-mq';

.contact__container {
    @include container;
    @include page-margins;
    display: flex;
}


.contact__section-container {
    display: flex;
    width: 100%;

    @include mq ($until: tablet) {
        flex-direction: column;
    }
}

.contact__section {
    width: 40%;

    &:first-child {
        margin-right: 100px;
    }

    @include mq ($until: tablet) {
        width: 100%;

        &:first-child {
            margin-right: 0;
        }
    }
}

.contact__description {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.contact__description-item {
    display: flex;
    align-items: center;
    gap: 10px;

    @include mq ($until: tablet) {
        flex-wrap: wrap;
    }
}

.contact__description-icon {
    display: flex;
    color: $global--primary-color;
    margin-right: 10px;
}

.contact__title {
    margin-top: 20px;
    margin-bottom: 40px;
}