@import '../../../assets/styles/mixins';
@import 'sass-mq';

.copyright__container {
    @include container;
    @include page-margins;
    margin-top: 150px;
}

.copyright__section-title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 40px;
}

.copyright__section {
    width: 60%;

    @include mq ($until: tablet) {
        width: 100%;
    }

    p {
        margin-bottom: 10px;
    }
}

.copyright__item-title {
    font-weight: bolder;
    font-size: 14px;
}