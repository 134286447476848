@import '../../../assets/styles/mixins.scss';

.partners__container {
    @include container;
    @include page-margins;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.partners__title {
    margin: 40px 0;
}

.partners__description {
    line-height: 2;
}

.partners__section {
    width: 70%;

    &:first-child {
        margin-right: 40px;
    }

    @include mq ($until: tablet) {
        width: 100%;

        &:first-child {
            margin-right: 0;
        }
    }
}

.partners__banner {
    margin-top: 100px;
    height: 200px;
}

.partners__partner-item {
    width: 300px;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 1px 2px 13px -2px rgba(0, 0, 0, 0.2);
    padding: 20px;

    &:nth-child(4n) {
        margin-right: 0;
    }

    @include mq ($until: tablet) {
        margin-right: 0;
    }
}