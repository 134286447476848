@import '../../assets/styles/mixins';
@import '../../assets/styles/globals';
@import 'sass-mq';

$margin-right: 20px;
$items-per-row: 4;

.categories__container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.categories__category-items {
  display: flex;
  justify-content: center;

  @include mq($until: tablet) {
    flex-direction: column;
  }
}

.categories__category-item {
  &:not(:last-child) {
    margin-right: $margin-right;
  }

  @include mq($until: tablet) {
    &:not(:last-child) {
      margin-right: 0;
      margin-bottom: 20px;
    }
  }
}

.categories__category-content {
  transition: 0.3s ease-in-out all;

  &_is-hidden {
    height: 0;
    padding: 0;
    margin-top: 0;

    .categories__category-description {
      transform: translateY(120px);
    }

    .categories__image-container {
      transform: translateY(-250px);
    }
  }

  overflow: hidden;
  height: 290px;
  display: flex;
  align-items: center;
  width: 80%;
  margin-top: 40px;
  padding: 20px 0;

  @include mq($until: tablet) {
    width: 100%;
    flex-direction: column;
    height: auto;

    &_is-hidden {
      .categories__category-description {
        display: none;
      }

      .categories__image-container {
        display: none;
      }
    }
  }
}

.categories__image-container {
  width: 40%;
  width: 250px;
  height: 250px;
  margin-right: 20px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s ease-in-out all;
  @include mq ($until: tablet) {
    width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
  }
}

.categories__image {
  height: 100%;

  &_is-inverted {
    height: auto;
    width: 100%;
  }
}


.categories__category-description {
  width: 60%;
  transition: 0.3s ease-in-out all;
  transform: translateY(0);
   @include mq ($until: tablet) {
     width: 100%;
   }
}