@import '../../assets/styles/globals';
@import 'sass-mq';

.nav-item {
    cursor: pointer;
    text-decoration: none;
    font-size: 16px;
    position: relative;
    color: $global--font-color;
    font-weight: bold;


    &_is-transparent {
        color: #fff;
    }

    &:hover {
        color: $global--primary-color;
    }

    &_is-active {
        color: $global--primary-color;
    }

    @include mq ($until: tablet) {
       color: #fff;
       &_is-active {
           color: $global--primary-color;
       }
    }
}