@import '../../../assets/styles/mixins.scss';

.about__container {
    @include container;
    @include page-margins;
    display: flex;
}

.about__title {
    margin: 40px 0;
}

.about__description {
    line-height: 2;
}

.about__section {
    width: 70%;

    &:first-child {
        margin-right: 40px;
    }

    @include mq ($until: tablet) {
        width: 100%;

        &:first-child {
            margin-right: 0;
        }
    }
}

.about__banner {
    margin-top: 100px;
    height: 200px;
}