@import './globals';
@import '~sass-mq';

@mixin container {
  margin: 0 auto;
  padding: 0 40px;

  @include mq ($until: tablet) {
    padding: 0 10px;
    max-width: 100%;
  }
}

@mixin page-margins {
  margin-top: 40px;
  margin-bottom: 40px;
}

@mixin button {
  padding: 10px 20px;
  background-color: $global--primary-color;
  color: #fff;
  text-align: center;
  cursor: pointer;
  transition: 0.3s ease-in-out transform;
  border-radius: 20px;

  &:hover {
    background-color: lighten($global--primary-color, 10%);
  }

  &:active {
    transform: scale(0.96);
  }
}