@import '../../assets/styles/globals.scss';
@import 'sass-mq';

.category-item {
  &_is-selected {
    .category-item__name-container {
      color: #fff;
      background-color: $global--primary-color;
      transform: translateY(-4px);
    }
  }
}

.category-item__name-container {
  border: 1px solid $global--primary-color;
  transition: all .3s ease;
  cursor: pointer;
  padding: 12px 25px;
  text-transform: uppercase;
  border-radius: 50px;
  color: $global--primary-color;

  &:hover {
    color: #fff;
    background-color: lighten($global--primary-color, 10%);
    transform: translateY(-4px);
  }

  @include mq ($until: tablet) {
    width: 100%;

    &:not(:last-child) {
      margin-right: 0;
      margin-bottom: 20px;
    }

    &_is-mask {
      background-color: #cccccc;
    }
  }
}

.category-item__image-container {
  flex: 1;
  display: flex;
  align-items: center;
  overflow: hidden;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(#000, 0.4);
  }

  &:hover {
    &:after {
      background-color: rgba(#000, 0.2);
    }
  }
}

.category-item__image {
  height: 100%;
}

.category-item__name-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.category-item__name {
  font-size: 16px;
}

.category-item__mask {
  margin: 10px;
  padding: 10px;
  width: 80%;
  border-radius: 20px;
  background-color: #cccccc;
}

.category-item__content-container {
  position: absolute;
}