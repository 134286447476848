@import '../../assets/styles/mixins.scss';

.app {
  height: 100%;
}

.app__container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.app__header {
  flex-shrink: 0;
  height: 100px;
  width: 100%;
  background-color: #fff;
  z-index: 999;
  transition: 0.3s ease-in-out all;
  box-shadow: 1px 1px 20px 0px #00000036;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  z-index: 9999;

  &_is-transparent {
    background-color: transparent;
    box-shadow: none;
  }

  &_is-fixed {
     height: 60px;
  }


  &_is-fixed#{&}_is-transparent {
    background-color: #fff;
    box-shadow: 1px 1px 20px 0px #00000036;
  }
}

.app__body {
  flex: 1;
}

.app__footer {
  height: 60px;
  flex-shrink: 0;

  @include mq ($until: tablet) {
    height: auto;
  }
}

.app__navigation {
  position: fixed;
  bottom: 100px;
  right: 40px;
  transition: 0.3s ease-in-out all;

  &_is-hidden {
    display: none;
  }
}

.app__navigation-item {
  width: 50px;
  height: 50px;
  background-color: $global--primary-color;
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 0.3s ease-in-out transform;

  &:hover {
    background-color: lighten($global--primary-color, 10%);
  }

  &:active {
    transform: scale(0.95);
  }

  &:after {
    content: '';
    width: 15px;
    height: 15px;
    border-top: 2px solid #fff;
    border-right: 2px solid #fff;
    transform: rotate(-45deg);
    position: absolute;
    top: 20px;
  }
}