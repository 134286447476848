@import '../../../assets/styles/mixins.scss';
@import '../../../assets/styles/globals.scss';

.home__container {
  @include container;
  @include page-margins;
}

.home__banner {
  height: $global--banner-height;

  @include mq ($until: tablet) {
    height: $global--banner-height-mobile;
  }
}

.home__categories-container {
  margin-bottom: 120px;
   @include mq ($until: tablet) {
     margin-bottom: 80px;
   }
}

.home__categories-title {
  font-size: 36px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 80px;
}

.home__section-container {
  display: flex;
  justify-content: space-between;

  @include mq ($until: tablet) {
    flex-direction: column;
  }
}

.home__section {
  width: 45%;
  display: flex;
  flex-direction: column;

  @include mq ($until: tablet) {
    width: 100%;

    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }
}

.home__section-title {
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 20px;
}

.home__section-content {
  flex: 1;
  margin-bottom: 20px;
}

.home__section-button-container {
  width: 150px;
  display: flex;
}

.home__section-button {
 @include button;
}