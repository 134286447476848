@import '../../../assets/styles/mixins.scss';
@import 'sass-mq';

.work-with-us__container {
    @include container;
    @include page-margins;
    display: flex;
}

.work-with-us__title {
    margin: 40px 0;
}

.work-with-us__description {}

.work-with-us__section {
    width: 70%;

    @include mq ($until: tablet) {
        width: 100%;
    }
}

.work-with-us__image {
    width: 100%;
}

.work-with-us__item {
    margin-bottom: 20px;
}

.work-with-us__item-title {
    font-weight: bold;
    margin-bottom: 10px;
    font-size: 16px;
}