@import '../../assets/styles/globals.scss';
@import 'sass-mq';

.banner {
    height: 100%;
    position: relative;
}

.banner__image-container {
    height: $global--banner-height;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    @include mq ($until: tablet) {
        height: $global--banner-height-mobile;
    }
}

.banner__image {
    width: 100%;
    height: auto;

    @include mq ($until: tablet) {
        width: auto;
    }
}

.banner__text-container {
    position: absolute;
    background-color: rgba(#000, 0.3);
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    z-index: 99;
    font-family: $global--secondary-font;
}

.banner__text {
    color: #fff;
    font-size: 100px;
    font-weight: bold;
    text-transform: uppercase;
    font-family: $global--secondary-font;

    &_is-smaller {
        font-size: 42px;

        text-transform: capitalize;
        color: $global--secondary-color;
    }

    @include mq ($until: tablet) {
        font-size: 28px;

        &_is-smaller {
            font-size: 18px;
        }
    }

    @include mq ($from: tablet, $until:wide) {
        font-size: 50px;
    }
}