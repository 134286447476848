@import '../../../assets/styles/mixins.scss';

.partner-item {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.partner-item__image-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    overflow: hidden;
}


.partner-item__image {
    width: 100%;

    &_is-inverted {
        width: auto;
        height: 100%;
    }
}