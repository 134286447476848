@import-normalize;
@import './assets/styles/globals';
@import './assets/styles/reset';

.app-root {
  height: 100%;
}

html,
body {
  height: 100%;
  width: 100%;
}