@import '../../assets/styles/globals.scss';
@import 'sass-mq';

.page-banner {
    height: 100%;
    position: relative;
}

.page-banner__image-container {
    height: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;

    &:after {
        content: '';
         top: 0;
         bottom: 0;
         right: 0;
         left: 0;
         position: absolute;
        background-color: rgba(rgb(0, 0, 0), 0.2);
        z-index: 1;
    }
}

.page-banner__image {
    width: 100%;
    @include mq ($until: tablet) {
        min-width: 100%;
        height: 100%;
    }
}

.page-banner__label-container {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
}

.page-banner__label {
    font-size: 41px;
    color: #fff;
    text-transform: uppercase;
    font-family: $global--secondary-font;
}