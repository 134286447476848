@import '../../assets/styles/globals.scss';
@import '../../assets/styles/mixins.scss';

.title-item {

}
.title-item__container {
    @include container;
    padding: 5px 0;
}

.title-item__title {
    color: $global--font-color;
    font-size: 26px;
    font-weight: bold;
}