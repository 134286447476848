$mq-breakpoints: (
    mobile: 320px,
    mobileLarge: 425px,
    tablet: 760px,
    desktop: 980px,
    wide: 1300px,

    // Tweakpoints
    desktopAd: 810px,
    mobileLandscape: 480px);


$global--content-width: 1100px;
$global--banner-height: calc(100vh - 150px);
$global--banner-height-mobile: 400px;
$global--primary-color: #e30211;
$global--secondary-color: #ffcc06;
$global--font-color: #242021;
$global--background-color:#fff;
$global--font-base: 'Montserrat Alternates',
sans-serif;
$global--secondary-font: 'Nunito',
sans-serif;