@import '../../../assets/styles/mixins.scss';
@import '../../../assets/styles/globals.scss';
@import 'sass-mq';

.header {
  height: 100%;
  width: 100%;

  &_is-fixed {
    .header__logo {
      width: 100px;
      height: 49.5px;
    }
  }

  &_is-transparent {
    .header__nav-menu {
      color: #fff;
    }
  }

  &_is-menu-opened {
    .header__nav-container {
      display: flex;
    }

    .header__nav-close {
      @include mq ($until: tablet) {
        display: block;
      }
    }
  }
}

.header__container {
  @include container;
  display: flex;
  align-items: center;
  height: 100%;

  @include mq ($until: tablet) {
    justify-content: space-between;
  }
}

.header__logo-link {
  display: flex;
}

.header__logo-container {
  cursor: pointer;
  display: flex;
}

.header__logo {
  transition: 0.3s ease-in-out all;
  width: 160px;
  height: 79px;
}

.header__nav-container {
  flex: 1;
  display: flex;
  justify-content: flex-end;

  @include mq ($until: tablet) {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(#000, 0.9);
    display: none;
  }
}

.header__nav-item {
  padding: 15px 30px;

  &:last-child {
    padding-right: 0;

    @include mq ($until: tablet) {
      padding: 15px 30px;

    }
  }

}

.header__nav-menu {
  display: none;
  color: #000;

  @include mq ($until: tablet) {
    display: block;
  }
}

.header__nav-close {
  display: none;

  @include mq ($until: tablet) {
    color: #fff;
    position: fixed;
    top: 20px;
    right: 20px;
    z-index: 9999999;
  }

}