@import '../../../assets/styles/mixins.scss';
@import '../../../assets/styles/globals.scss';

.footer {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.footer__container {
  display: flex;
  flex-direction: column;
}

.footer__top {
  background-color: #d8d8d8;
}

.footer__top-container {
  @include container;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.footer__top-section {
  padding: 20px 0;
}

.footer__bottom {
  background: #fff;
  width: 100%;
}

.footer__bottom-container {
  @include container;
  padding: 20px 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;


  @include mq ($until: tablet) {
    padding: 10px;
  }
}

.footer__nav-container {
  display: flex;

  @include mq ($until: tablet) {
    flex-direction: column;
  }
}

.footer__nav-item {
  margin-right: 40px;
  cursor: pointer;

  @include mq ($until: tablet) {
    margin-right: 0;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.footer__social-item-container {
  display: flex;
}

.footer__social-item {
  &:not(:last-child) {
    margin-right: 20px;
  }
}

.footer__social-item-link {
  color: #fff;
}

.footer__item-title {
  font-size: 18px;
  font-weight: bold;
  color: grey;
  margin: 15px 0;
}

.footer__item-link {
  color: grey;
}

.footer__item {
  font-size: 32px;
  color: $global--primary-color;
}

.footer__copyright {
  gap: 10px;
  display: flex;
  justify-content: center;

  &:first-child {
    margin-bottom: 5px;
  }
}

.footer__copyright-link {
  color: $global--font-color;

  &_is-secondary {
    color: #75a8ab;
  }
}